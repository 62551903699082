import { graphql } from 'gatsby'
import React from 'react'
import IndexLayout from '../layouts'
import { ingredientsBySection } from '../common/sections'
import { CartContext } from '../components/shared/CartProvider'
import IngredientTile from '../components/shared/IngredientTile'


const AddOns = ({ data }) => {
  const ingredients = data.allContentfulSellableIngredient.nodes
  const sectionedIngredients = ingredientsBySection(ingredients)

  return (
    <IndexLayout>
      <div className="container">
        <div className="row pt-5">
          <div className="col-12">
            <h2>The Rest</h2>
            <p>Here's some other things we love to keep around our kitchens. Some snacks. Some fruit. Some essentials.</p>
          </div>
        </div>
        {sectionedIngredients.map((section, sectionIndex) => {
          return (
            <div className="row" key={`section-${sectionIndex}`}>
              <div className="col-12">
                <h3>{section.sectionName}</h3>
              </div>
              {section.ingredients.map((ingredient, ingredientIndex) => {
                return (
                  <IngredientTile ingredient={ingredient} key={`ingredient-tile-${ingredient.sku}`} />
                )
              })}
            </div>
          )
        })}

        <CartContext.Consumer>
          {(cart) => (
            <div className="row pt-4">
              <div className="col-8 col-md-4 mx-auto text-center">
                <button className="btn btn-primary btn-block" onClick={cart.toggleCart}>Checkout</button>
              </div>
            </div>
          )}
        </CartContext.Consumer>
      </div>
    </IndexLayout>
  )
}

export const query = graphql`
  query {
    allContentfulSellableIngredient(filter: {isAddon: {eq: true}}) {
      nodes {
        name
        slug
        priceCents
        sku
        description {
          raw
        }
        picture {
          file {
            url
          }
        }
        priceCents
        section
      }
    }
  }
`

export default AddOns
